<template>
	<v-container class="background-transparent">
		<admin-default-header>Organizaton Settings</admin-default-header>


		<div class="brand-tabs mt-5">

			<v-tabs
				v-model="currentItem"
				light
				background-color="transparent"
				:slider-color="$store.getters.theme.mainAccentColor"
				slider-size="8"
				color="#000"
				class="brand-tabs-container"
			>
				<v-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:href="`#${tab.component}`"
					class="organization-settings-tab-button primary-text"
					@click="currentItem = tab.component"
				>
					{{ tab.title }}
				</v-tab>
			</v-tabs>

			<v-tabs-items
				v-model="currentItem"
				class="organization-settings-tabs-container background-transparent"
			>
				<v-tab-item
					v-for="tab in tabs"
					:key="tab.title"
					:value="tab.component"
					class="organization-settings-tab"
				>
					<keep-alive>
						<component :is="tab.component" />
					</keep-alive>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</v-container>
</template>

<script>
import OrganizationSettingsBilling from "./OrganizationSettingsBilling.vue";
import OrganizationSettingsDetails from "./OrganizationSettingsDetails.vue";
import OrganizationSettingsHomePage from "./OrganizationSettingsHomePage.vue";
import OrganizationSettingsRoles from "./OrganizationSettingsRoles.vue";
import AdminDefaultHeader from "../../../components/AdminDefaultHeader.vue";
import AdminDefaultDescription from "../../../components/AdminDefaultDescription.vue";

export default {
	name: "OrganizationSetting",
	components: {
		OrganizationSettingsBilling,
		OrganizationSettingsDetails,
		OrganizationSettingsHomePage,
		OrganizationSettingsRoles,
		AdminDefaultHeader,
		AdminDefaultDescription
	},
	data() {
		return {
			templateName: "",
			currentItem: "OrganizationSettingsDetails",
			tabs: [
				{
					title: "Details & Branding",
					value: "details",
					component: "OrganizationSettingsDetails"
				},
				{
					title: "Home Page",
					value: "home",
					component: "OrganizationSettingsHomePage"
				},
				{
					title: "Roles",
					value: "roles",
					component: "OrganizationSettingsRoles"
				},
				{
					title: "Billing",
					value: "billing",
					component: "OrganizationSettingsBilling"
				}
			]
		};
	}
};
</script>

<style></style>
