<template>
	<v-container class="ma-0 pa-0 background-transparent">
		<AdminDefaultSubheader class="mb-10"
			>Home Page Customization</AdminDefaultSubheader
		>
		<InputTextArea
			v-model="$store.state.organization.homePageHeader"
			:id="'organization_settings_home_header'"
		>
			<template #label> Main Header </template>
		</InputTextArea>
		<InputTextArea
			v-model="$store.state.organization.homePageSubheader"
			:id="'organization_settings_home_subheader'"
		>
			<template #label> Subheader (Optional) </template>
		</InputTextArea>
		<InputTextArea
			v-model="$store.state.organization.homePageMainText"
			:id="'organization_settings_home_text'"
		>
			<template #label> Main Text </template>
		</InputTextArea>
		<InputTextArea
			v-if="organizationId === 1"
			v-model="bannerText"
			:id="'organization_settings_home_text'"
		>
			<template #label> Started banner text </template>
		</InputTextArea>
		<InputImageFile
			v-model="homePageMainImage"
			:valueName="'homePageMainImage'"
			:id="'organization_main_image'"
			:name="'organization_main_image'"
			@change="changeMainImage($event)"
		>
			<template #span>Main Image</template>
			<template #button>Upload New Image</template>
		</InputImageFile>

		<InputImageFile
			v-model="homePageMainBackgroundImage"
			:valueName="'homePageMainBackgroundImage'"
			:id="'organization_background_image'"
			:name="'organization_background_image'"
			@change="changeBackgroundImage($event)"
		>
			<template #span>Background Image</template>
			<template #button>Upload New Image</template>
		</InputImageFile>

		<FormMessage v-if="message" :type="status">
			{{ message }}
		</FormMessage>

		<v-row class="my-14">
			<v-col cols="6"
				><v-btn
					class="admin-primary-button secondary-contrast-background"
					:loading="isLoading"
					:disabled="true"
					@click="$store.dispatch('getOrganizationInfo')"
					>Preview Home Page</v-btn
				></v-col
			>
			<v-col cols="6">
				<div class="d-flex justify-end mt-2">
					<v-btn
						class="admin-primary-button primary-contrast-background"
						:loading="isLoading"
						@click="save()"
						>Accept Changes</v-btn
					>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FormMessage from "@/components/FormMessage.vue";

export default {
	name: "OrganizationSettingsHomePage",
	components: {
		FormMessage
	},
	computed: {
		...mapGetters(["isLoading", "organization"]),
		organizationId() {
			return this.organization.id;
		}
	},
	data() {
		return {
			message: "",
			status: "",
			homePageMainImage: null,
			homePageMainBackgroundImage: null,
			bannerText: ""
		};
	},
	methods: {
		changeMainImage(val) {
			this.$store.dispatch("setMainImage", val);
		},
		changeBackgroundImage(val) {
			this.$store.dispatch("setBackgroundImage", val);
		},
		async save() {
			const response = await this.$store.dispatch(
				"saveOrganizationSettingsHomePage"
			);

			this.message =
				"Organization settings has been successfully updated";
			this.status = response.status;
		}
	}
};
</script>

<style></style>
