<template>
	<v-container
		class="admin-default--organization-settings background-transparent"
	>
		<v-row>
			<AdminDefaultSubheader class="mb-10"
				>Your organization site address:
				{{ organizationSiteAddress }}
			</AdminDefaultSubheader>
		</v-row>
		<v-row>
			<v-col cols="4">
				<AdminDefaultSubheader class="mb-10"
					>Organization
				</AdminDefaultSubheader>
				<InputTextField
					:id="'organization_name'"
					v-model="$store.state.organization.name"
				>
					<template #label>Organization Label</template>
				</InputTextField>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<InputTextField
					:id="'organization_description'"
					v-model="$store.state.organization.description"
				>
					<template #label>Description</template>
				</InputTextField>
			</v-col>
		</v-row>
		<v-container class="d-flex flex-row align-center pa-0 ma-0 mb-16">
			<AdminDefaultSubheader>Branding</AdminDefaultSubheader>
			<v-btn
				class="admin-primary-button secondary-contrast-background ml-4"
				:loading="isLoading"
				:disabled="true"
				@click="$store.dispatch('getOrganizationInfo')"
				>Preview Branding</v-btn
			>
		</v-container>


		<InputImageFile
			v-model="logo"
			:id="'organization_logo'"
			:name="'organization_logo'"
			@change="changeLogo($event)"
		>
			<template #span>Organization Logo</template>
			<template #button>Upload New Logo</template>
		</InputImageFile>

		<InputImageFile
			v-model="headerImage"
			:id="'organization_header_image'"
			:name="'organization_header_image'"
			@change="changeHeaderImage($event)"
		>
			<template #span>Header Image</template>
			<template #button>Upload New Logo</template>
		</InputImageFile>

		<InputComboBox
			v-model="$store.state.organization.theme"
			:id="'organization_settings_theme'"
			:items="[organizationExtras]"
			:itemText="'theme'"
			:itemValue="'theme'"
			@change="changeTheme($event)"
		>
			<template #label> Theme </template>
		</InputComboBox>

		<InputColorPicker
			v-model="$store.state.organization.backgroundColor"
			:valueName="'backgroundColor'"
			:label="'Background Color'"
			:id="'organization_settings_background_color'"
			:name="'organization_settings_background_color'"
		/>
		<InputColorPicker
			v-model="$store.state.organization.mainAccentColor"
			:valueName="'mainAccentColor'"
			:label="'Main Accent Color'"
			:id="'organization_settings_main_accent_color'"
			:name="'organization_settings_main_accent_color'"
		/>
		<InputColorPicker
			v-model="$store.state.organization.secondaryAccentColor"
			:valueName="'secondaryAccentColor'"
			:label="'Secondary Accent Color'"
			:id="'organization_settings_secondary-accent-color'"
			:name="'organization_settings_secondary-accent-color'"
		/>
		<InputColorPicker
			v-model="$store.state.organization.linkColor"
			:valueName="'linkColor'"
			:label="'Link Color'"
			:id="'organization_link_color'"
			:name="'organization_link_color'"
		/>

		<form-message v-if="message" :type="status">{{ message }}</form-message>

		<v-row class="my-14">
			<v-col cols="6">
				<v-btn
					class="admin-primary-button secondary-contrast-background"
					:loading="isLoading"
					:disabled="true"
					@click="$store.dispatch('getOrganizationInfo')"
					>Preview Branding</v-btn
				></v-col
			>
			<v-col cols="6">
				<div class="d-flex justify-end mt-2">
					<v-btn
						class="admin-primary-button primary-contrast-background"
						:loading="isLoading"
						@click="save()"
						>Accept Changes</v-btn
					>
				</div></v-col
			>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InputImageFile from "../../../components/InputImageFile.vue";
import InputColorPicker from "../../../components/InputColorPicker.vue";
import InputTextField from "../../../components/InputTextField.vue";
import AdminDefaultSubheader from "../../../components/AdminDefaultSubheader";
import FormMessage from "@/components/FormMessage.vue";
import InputComboBox from "@/components/InputComboBox.vue";

export default {
	name: "OrganizationSettingsDetails",
	components: {
		InputImageFile,
		InputColorPicker,
		AdminDefaultSubheader,
		FormMessage,
		InputTextField,
		InputComboBox
	},
	computed: {
		...mapGetters(["organizationExtras", "organization", "isLoading"]),
		organizationId() {
			if (this.$route.query.organizationId !== undefined) {
				return this.$route.query.organizationId;
			} else if (this.organization?.id !== undefined) {
				return this.organization?.id;
			} else if (localStorage.getItem("organizationId")) {
				return localStorage.getItem("organizationId");
			} else {
				return 1;
			}
		},
		organizationSiteAddress() {
			return `${document.location.origin}?organizationId=${this.organizationId}`;
		}
	},
	data() {
		return {
			message: "",
			status: "",
			logo: null,
			headerImage: null
		};
	},
	methods: {
		async save() {
			const response = await this.$store.dispatch(
				"saveOrganizationSettingsDetails"
			);

			this.message =
				"Organization settings has been successfully updated";
			this.status = response.status;
		},
		changeLogo(val) {
			this.$store.dispatch("setLogo", val);
		},
		changeHeaderImage(val) {
			this.$store.dispatch("setHeaderImage", val);
		},
		changeTheme(theme) {
			if (theme === undefined) return;
			if (typeof theme === "string") {
				this.$store.dispatch("setSingleValue", {
					key: "theme",
					value: theme
				});
				return;
			}
			this.$store.dispatch("setOrganizationSettingsDetails", {
				organizationId: this.organization.id || 1,
				theme: theme.theme,
				backgroundColor: theme.background_color,
				mainAccentColor: theme.main_accent_color,
				secondaryAccentColor: theme.secondary_accent_color,
				linkColor: theme.link_color
			});
			this.$store.dispatch("setThemeColors");
		}
	},
	async beforeDestroy() {
		this.$store.dispatch("restoreColors");
		this.$store.dispatch("setThemeColors");
	}
};
</script>

<style></style>
